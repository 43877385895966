import request from "../utils/qrAuthRequest"

//查询授权记录状态
export const queryAuthRecord = (authId) => {
    return request({
        url:'/sku/queryAuthRecord/'+authId,
        method: 'GET'
    })
}

//查询授权记录状态
export const operationAuth = (authId, operationAuthStatus, code) => {
    return request({
        url:'/sku/operationAuth',
        method: 'POST',
        params: {authId, operationAuthStatus, code}
    })
}
