<template>
<van-row type="flex">
  <van-col>
    <div class="wrap">
      <div class="inner">
        <div class="content-wrap">
          <van-cell-group>
            <van-cell title="操作类型" :value="operationType" />
            <van-cell title="说明" :value="this.authInfo.note" />
            <van-cell title="申请人" :value="this.authInfo.operationUserName" />
            <van-cell title="状态" :value="approvalStatus" />
            <van-cell title="申请时间" :value="this.authInfo.createdAt|filterCreateTime"/>
          </van-cell-group>
        </div>
        <div class="btn-wrap">
          <van-row type="flex" gutter="10">
            <van-col :span="12">
              <van-button type="danger" size="small" block :disabled="!couldAction" round @click="authHandle('denied')" >拒绝授权</van-button>
            </van-col>
            <van-col :span="12">
              <van-button type="primary" size="small" block :disabled="!couldAction" round @click="authHandle('authorized')">同意授权</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <loading-overlay :loading="loading"/>
  </van-col>
</van-row>
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay";
import {operationAuth, queryAuthRecord} from "@/api/qrAuth";
import Vue from "vue";
import {Button, Cell, CellGroup, Col, Dialog, Row} from "vant";
import {format} from "date-fns";
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Cell);
Vue.use(CellGroup);
const defaultAuthInfo = {
  id:'',
  dataId:'',
  createdAt:'',
  note:'',
  operationType:'',
  operationUserName:'',
  status:''
}
const approvalStatusEnums= [
  {value:"draft", label: "待处理"},
  {value:"authorized", label: "已授权"},
  {value:"denied", label: "已拒绝"},
  {value:"used", label: "已使用"},
]
export default {
  name: "QrAuth",
  components: {LoadingOverlay},
  data(){return {
    loading: false,
    code: '',
    authId: '',
    authInfo: Object.assign({}, defaultAuthInfo)
  }},
  mounted() {
    this.code = this.$route.query.code
    this.authId = this.$route.params.authId
    this.getAuthInfo()
  },
  filters:{
    filterCreateTime(date){
      if(!date){
        return ''
      }
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
    },
  },
  methods:{
    authHandle(operationAuthStatus){
      this.loading = true
      operationAuth(this.authId, operationAuthStatus, this.code).then(res=>{
        this.loading = false
        if(res.code === 200){
          Dialog({ message: '操作成功' }).then(()=>{
            this.getAuthInfo()
          });

        }else{
          Dialog({ message: res.message||'操作失败' }).then(()=>{
            this.flushPage()
          });

        }

      }, err=>{
        console.log(err)
        this.loading = false
        Dialog({ message: '操作出错了', confirmButtonText:'点击重试' }).then(()=>{
          this.flushPage()
        });
      })
    },
    flushPage(){
      console.log('flushPage')
      this.$router.replace({name:'qrAuth', params:{authId: this.authId}, query:{}})
    },
    getAuthInfo(){
      if(this.loading){
        return true
      }else{
        this.loading = true
      }
      queryAuthRecord(this.authId).then(res=>{
        this.loading = false
        console.log(res)
        if(res.code === 200){
          this.authInfo = Object.assign({}, defaultAuthInfo, res.data)
          console.log(this.authInfo)
        }else{
          Dialog.alert({
            message: res.message|'取数据失败',
          })
        }
      }, error=>{
        this.loading = false
        Dialog.alert({
          message: '取数据出错',
        })
        console.log(error)
      })
    }
  },
  computed:{
    couldAction(){
      return this.authInfo.status === "draft" && !!this.code
    },
    approvalStatus(){
      const status = approvalStatusEnums.find(item=>{return item.value === this.authInfo.status})
      return status ? status.label : ''
    },
    operationType(){
      let operationTypeName = ''
      switch (this.authInfo.operationType) {
        case "productPrice":
          operationTypeName = '商品改价'
          break
        default:
          break
      }
      return operationTypeName
    },

  }

}
</script>

<style scoped lang="sass">
.wrap
  height: 100vh
  width: 100vw
  display: flex
  background-color: #FFFFFF
  box-sizing: border-box
  padding-bottom: constant(safe-area-inset-bottom)
  padding-bottom: env(safe-area-inset-bottom)
  .inner
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    //background-color: #f0f
    .content-wrap
      display: flex
      flex-direction: column
      flex: 1

      //background-color: #f00
    .btn-wrap
      display: flex
      flex-direction: column
      width: 100%
      padding: 10px
      box-sizing: border-box
      //background-color: #0ff

</style>
