import { render, staticRenderFns } from "./QrAuth.vue?vue&type=template&id=505a760f&scoped=true&"
import script from "./QrAuth.vue?vue&type=script&lang=js&"
export * from "./QrAuth.vue?vue&type=script&lang=js&"
import style0 from "./QrAuth.vue?vue&type=style&index=0&id=505a760f&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505a760f",
  null
  
)

export default component.exports